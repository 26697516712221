// Spain
export const locale = {
  plans: {
    titles: {
      mainTitle2: '¡Bienvenidos a Lizto!',
      planTitle2: 'Premium',
      planTitle3: 'Profesional',
      planTitle4: 'Max',
      planTitleEntrepreneur: 'Emprendedor',
      whatsappTitle: 'Whatsapp',
      smsTitle: 'SMS',
      complementsTitle: 'Complementa tu planes',
      complementsSubTitle: 'Optimiza tu rendimiento integrando herramientas para facilitar más la administración de tu negocio.'
    },
    buttons: {
      select1: 'Planes nacionales ',
      select2: 'Planes internacionales',
      radioButton1: 'Planes mensuales',
      radioButton2: 'Planes Anuales',
      wantItButton: '¡Lo quiero ya!'
    },
    mostSeller: '¡Más vendido!',
    price: 'Precio',
    plansFeatures: {
      priceTitles: {
        titleMonthly: 'Precio por sede mensual',
        titleAnual: 'Precio por sede anual'
      },
      allFeatures: {
        nameBox: 'Sistema de caja y facturación.',
        nameReports: 'Informes.',
        nameCommission: 'Gestión de comisiones por venta de servicios y productos.',
        nameManagement: 'Gestión de clientes.',
        nameInventory: 'Control de Inventarios y Compras.',
        nameBookings: 'Agenda y Reservas en línea por redes sociales.',
        nameStaff: 'Staff de trabajo y usuarios por sede.',
        nameSupport: 'Capacitación y soporte técnico.',
        nameLoyalty: 'Programa de fidelización con puntos.',
        nameSatisfaction: 'Encuestas de satisfacción',
        nameReminder: 'Recordatorios automáticos de cita vía mensaje de texto SMS, Whatsapp y Email.',
        nameDigitalOrders: 'Comandas y órdenes de servicio digitales. (Aplicación web para los especialistas)',
        nameBillingPos: 'Integración para emisión de facturación electrónica y POS Electrónico. ( Documentos electrónicos es adicional )'
      },
      sideFeatures: {
        staffUntil5: 'Hasta 5',
        staffUntil10: 'Hasta 10',
        staffUntil30: 'Hasta 30',
        staffUnlimited: 'Ilimitados',
        reminderEmail: 'Email',
        reminderEmailSms: 'Email',
        reminderApiSmsEmail: 'Email'
      }
    },
    currency: {
      monthlyNational: 'Mensual',
      anualNational: 'Anual',
      usd: 'USD'
    },
    complements: {
      whatsappGeneralDescription: 'Puedes comprar un paquete adicional de mensajes de whatsapp para activarlos en los planes Profesional, Premium y Max',
      whatsappGeneralDescriptionInternational: 'Puedes comprar un paquete adicional de mensajes de whatsapp para activarlos en los planes Profesional y Premium',
      whatsappDisclamerTitle: '*Los paquetes de mensajes no vencen.',
      whatsappDisclamerText: 'Solo vencen hasta agotar la cantidad de mensajes que tienes en tu cuenta, al agotar la cantidad de mensajes debes volver a recargar tu cuenta adquiriendo un nuevo paquete de mensajes de whatsapp.',
      whatsappTitles: {
        title3000: 'Plan 3.000',
        title2000: 'Plan 2.000',
        title1000: 'Plan 1.000',
        title5000: 'Plan 5.000',
        title10000: 'Plan 10.000'
      },
      whatsappDescription: {
        description3000: '3.000 mensajes',
        description2000: '2.000 mensajes',
        description1000: '1.000 mensajes',
        description5000: '5.000 mensajes',
        description10000: '10.000 mensajes'
      },
      smsDisclamerTitle: '*Los paquetes de mensajes no vencen.',
      smsDisclamerText: 'Solo vencen hasta agotar la cantidad de mensajes que tienes en tu cuenta, al agotar la cantidad de mensajes debes volver a recargar tu cuenta adquiriendo un nuevo paquete de mensajes de sms.',
      smsTitles: {
        title500: 'Plan 500',
        title1000: 'Plan 1.000',
        title3000: 'Plan 3.000',
        title5000: 'Plan 5.000',
        title10000: 'Plan 10.000',
        title20000: 'Plan 20.000'
      },
      smsDescription: {
        description500: '500 Mensajes',
        description1000: '1.000 Mensajes',
        description3000: '3.000 Mensajes',
        description5000: '5.000 Mensajes',
        description10000: '10.000 Mensajes',
        description20000: '20.000 Mensajes'
      }
    }
  },
  base: {
    documentTitle: 'Lizto - Software para centro de belleza y estética',
    menu: {
      home: 'Inicio',
      features: 'Características',
      loginLizto: 'Ingreso a Lizto',
      contact: 'Contacto',
      plans: 'Planes'
    },
    text: {
      safeSite: 'Sitio Seguro',
      whatsappText: '¿Como podemos ayudarte?'
    }
  },
  contactFormComplte: {
    text1: 'Gracias',
    text2: 'pronto nos pondremos en contacto',
    buttons: {
      register: 'Volver a sitio web'
    }
  },
  banner: {
    text1: 'Software fácil y eficiente',
    text2: 'para peluquerias, centros de belleza, barberías y spa',
    buttons: {
      register: 'Mas información'
    }
  },
  companyTypes: {
    section: {
      title: 'Lizto se adapta muy fácil a tu negocio'
    },
    names: {
      hairdressers: 'Peluquerías',
      barbershops: 'Barberías',
      eyebrow_and_lash_spa: 'Spa de cejas y pestañas',
      nail_salon: 'Salón de uñas',
      // spa: 'Spa',
      hair_spa_salon: 'Hair spa salón'
    }
  },
  common: {
    text: {
      sendingMessage: 'Estamos enviando tu mensaje.',
      errorHasOccurred: 'A ocurrido un error'
    }
  },
  customers: {
    section: {
      title: 'Nuestros clientes'
    }
  },
  features: {
    section: {
      title: 'Características'
    },
    names: {
      calendar: 'Agenda',
      onlineBooking: 'Reservas online',
      inventories: 'Inventarios',
      loyalty: 'Fidelización de clientes',
      pos: 'Caja POS',
      reports: 'Informes',
      expenseControl: 'Control de gastos',
      manageMultipleLocations: 'Administra varias sedes'
    }
  },
  pricing: {
    section: {
      title: 'Precios'
    }
  },
  contact: {
    links: {
      termsAndConditions: 'Términos y Condiciones'
    },
    section: {
      title: 'Déjanos tus datos para más información'
    },
    form: {
      fields: {
        name: 'Nombre*',
        email: 'Email*',
        cellphone: 'Celular*',
        message: 'Mensaje*',
        acceptTermsAndConditions: '¿Aceptas el uso de tus datos personales de acuerdo a la Ley 1581 de 2012 y normas relacionadas y las políticas de protección de datos personales con el fin de suscribirte en nuestra base de datos con fines comerciales y de análisis?',
        policyProcessingPersonalData: 'Política de Tratamiento de Datos Personales*',
        send: 'Enviar'
      }
    }
  },
  countries: {
    af: 'Afganistán',
    al: 'Albania',
    dz: 'Argelia',
    as: 'Samoa Americana',
    ad: 'Andorra',
    ao: 'Angola',
    ai: 'Anguila',
    ag: 'Antigua y Barbuda',
    ar: 'Argentina',
    am: 'Armenia',
    aw: 'Aruba',
    au: 'Australia',
    at: 'Austria',
    az: 'Azerbaiyán',
    bs: 'Bahamas',
    bh: 'Baréin',
    bd: 'Bangladesh',
    bb: 'Barbados',
    by: 'Bielorrusia',
    be: 'Bélgica',
    bz: 'Belice',
    bj: 'Benín',
    bm: 'Islas Bermudas',
    bt: 'Bután',
    bo: 'Bolivia',
    ba: 'Bosnia y Herzegovina',
    bw: 'Botsuana',
    br: 'Brasil',
    io: 'Territorio Británico del Océano Índico',
    vg: 'Islas Vírgenes Británicas',
    bn: 'Brunéi',
    bg: 'Bulgaria',
    bf: 'Burkina Faso',
    bi: 'Burundi',
    kh: 'Camboya',
    cm: 'Camerún',
    ca: 'Canadá',
    cv: 'Cabo Verde',
    bq: 'Caribe neerlandés',
    ky: 'Islas Caimán',
    cf: 'República Centroafricana',
    td: 'Chad',
    cl: 'Chile',
    cn: 'China',
    cx: 'Isla de Navidad',
    cc: 'Cocos',
    co: 'Colombia',
    km: 'Comoras',
    cd: 'Congo',
    cg: 'Congo',
    ck: 'Islas Cook',
    cr: 'Costa Rica',
    ci: 'Costa de Marfil',
    hr: 'Croacia',
    cu: 'Cuba',
    cw: 'Curazao',
    cy: 'Chipre',
    cz: 'Republica checa',
    dk: 'Dinamarca',
    dj: 'Yibuti',
    dm: 'Dominica',
    ec: 'Ecuador',
    eg: 'Egipto',
    sv: 'El Salvador',
    gq: 'Guinea Ecuatorial',
    er: 'Eritrea',
    ee: 'Estonia',
    et: 'Etiopía',
    fk: 'Islas Malvinas',
    fo: 'Islas Faroe',
    fj: 'Fiyi',
    fi: 'Finlandia',
    fr: 'Francia',
    gf: 'Guayana Francesa',
    pf: 'Polinesia francés',
    ga: 'Gabón',
    gm: 'Gambia',
    ge: 'Georgia',
    de: 'Alemania',
    gh: 'Ghana',
    gi: 'Gibraltar',
    gr: 'Grecia',
    gl: 'Groenlandia',
    gd: 'Granada',
    gp: 'Guadalupe',
    gu: 'Guam',
    gt: 'Guatemala',
    gg: 'Guernesey',
    gn: 'Guinea',
    gw: 'Guinea-Bisáu',
    gy: 'Guayana',
    ht: 'Haití',
    hn: 'Honduras',
    hk: 'Hong Kong',
    hu: 'Hungría',
    is: 'Islandia',
    in: 'India',
    id: 'Indonesia',
    ir: 'Irán',
    iq: 'Irak',
    ie: 'Irlanda',
    im: 'Isla del hombre',
    il: 'Israel',
    it: 'Italia',
    jm: 'Jamaica',
    jp: 'Japón',
    je: 'Jersey',
    jo: 'Jordán',
    kz: 'Kazajistán',
    ke: 'Kenia',
    ki: 'Kiribati',
    xk: 'Kosovo',
    kw: 'Kuwait',
    kg: 'Kirguistán',
    la: 'Laos',
    lv: 'Letonia',
    lb: 'Líbano',
    ls: 'Lesoto',
    lr: 'Liberia',
    ly: 'Libia',
    li: 'Liechtenstein',
    lt: 'Lituania',
    lu: 'Luxemburgo',
    mo: 'Macao',
    mk: 'Macedonia',
    mg: 'Madagascar',
    mw: 'Malaui',
    my: 'Malasia',
    mv: 'Maldivas',
    ml: 'Malí',
    mt: 'Malta',
    mh: 'Islas Marshall',
    mq: 'Martinica',
    mr: 'Mauritania',
    mu: 'Mauricio',
    yt: 'Mayotte',
    mx: 'México',
    fm: 'Micronesia',
    md: 'Moldavia',
    mc: 'Mónaco',
    mn: 'Mongolia',
    me: 'Montenegro',
    ms: 'Montserrat',
    ma: 'Marruecos',
    mz: 'Mozambique',
    mm: 'Birmania',
    na: 'Namibia',
    nr: 'Nauru',
    np: 'Nepal',
    nl: 'Países Bajos',
    nc: 'Nueva Caledonia',
    nz: 'Nueva Zelanda',
    ni: 'Nicaragua',
    ne: 'Níger',
    ng: 'Nigeria',
    nu: 'Niue',
    nf: 'Isla Norfolk',
    kp: 'Corea del Norte',
    mp: 'Islas Marianas del Norte',
    no: 'Noruega',
    om: 'Omán',
    pk: 'Pakistán',
    pw: 'Palaos',
    ps: 'Palestina',
    pa: 'Panamá',
    pg: 'Papúa Nueva Guinea',
    py: 'Paraguay',
    pe: 'Perú',
    ph: 'Filipinas',
    pl: 'Polonia',
    pt: 'Portugal',
    pr: 'Puerto Rico',
    qa: 'Katar',
    re: 'Reunión',
    ro: 'Rumania',
    ru: 'Rusia',
    rw: 'Ruanda',
    bl: 'San Bartolomé',
    sh: 'Santa Elena',
    kn: 'San Cristóbal y Nieves',
    lc: 'Santa Lucía',
    mf: 'San Martín',
    pm: 'San Pedro y Miquelón',
    vc: 'San Vicente y las Granadinas',
    ws: 'Samoa',
    sm: 'San Marino',
    st: 'Santo Tomé y Príncipe',
    sa: 'Arabia Saudita',
    sn: 'Senegal',
    rs: 'Serbia',
    sc: 'Seychelles',
    sl: 'Sierra Leona',
    sg: 'Singapur',
    sx: 'San Martín',
    sk: 'Eslovaquia',
    si: 'Eslovenia',
    sb: 'Islas Salomón',
    so: 'Somalia',
    za: 'Sudáfrica',
    kr: 'Corea del Sur',
    ss: 'Sudán del Sur',
    es: 'España',
    lk: 'Sri Lanka',
    sd: 'Sudán',
    sr: 'Surinam',
    sj: 'Svalbard y Jan Mayen',
    sz: 'Suazilandia',
    se: 'Suecia',
    ch: 'Suiza',
    sy: 'Siria',
    tw: 'Taiwán',
    tj: 'Tayikistán',
    tz: 'Tanzania',
    th: 'Tailandia',
    tl: 'Timor Oriental',
    tg: 'Togo',
    tk: 'Tokelau',
    to: 'Tonga',
    tt: 'Trinidad y Tobago',
    tn: 'Túnez',
    tr: 'Turquía',
    tm: 'Turkmenistán',
    tc: 'Islas Turcas y Caicos',
    tv: 'Tuvalu',
    vi: 'Islas Vírgenes de EE.UU',
    ug: 'Uganda',
    ua: 'Ucrania',
    ae: 'Emiratos Árabes Unidos',
    gb: 'Reino Unido',
    us: 'Estados Unidos',
    uy: 'Uruguay',
    uz: 'Uzbekistán',
    vu: 'Vanuatu',
    va: 'Ciudad del Vaticano',
    ve: 'Venezuela',
    vn: 'Vietnam',
    wf: 'Wallis y Futuna',
    eh: 'Sahara Occidental',
    ye: 'Yemen',
    zm: 'Zambia',
    zw: 'Zimbabue',
    ax: 'Isla de Ålands'
  }
}
