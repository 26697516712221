
const configVMoney = {
  money: {
    decimal: ',',
    thousands: '.',
    prefix: '$ ',
    suffix: '',
    precision: 0,
    masked: false /* doesn't work with directive */
  }
}

function between (min, n, max) {
  return Math.max(min, Math.min(n, max))
}

function toStr (value) {
  return value ? value.toString() : ''
}

function onlyNumbers (input) {
  return toStr(input).replace(/\D+/g, '') || '0'
}

// Uncaught RangeError: toFixed() digits argument must be between 0 and 20 at Number.toFixed
function fixed (precision) {
  return between(0, precision, 20)
}

function numbersToCurrency (numbers, precision) {
  const exp = Math.pow(10, precision)
  const float = parseFloat(numbers) / exp
  return float.toFixed(fixed(precision))
}

function addThousandSeparator (integer, separator) {
  return integer.replace(/(\d)(?=(?:\d{3})+\b)/gm, `$1${separator}`)
}

function joinIntegerAndDecimal (integer, decimal, separator) {
  return decimal ? integer + separator + decimal : integer
}

function format (input, opt = configVMoney.money) {
  if (input === null || input === undefined) {
    return 0
  }
  if (typeof input === 'number') {
    input = input.toFixed(fixed(opt.precision))
  }
  const negative = input.indexOf('-') >= 0 ? '-' : ''

  const numbers = onlyNumbers(input)
  const currency = numbersToCurrency(numbers, opt.precision)
  const parts = toStr(currency).split('.')
  let integer = parts[0]
  const decimal = parts[1]
  integer = addThousandSeparator(integer, opt.thousands)
  return opt.prefix + negative + joinIntegerAndDecimal(integer, decimal, opt.decimal) + opt.suffix
}

function unformat (input, precision = 0) {
  const negative = input.indexOf('-') >= 0 ? -1 : 1
  const numbers = onlyNumbers(input)
  const currency = numbersToCurrency(numbers, precision)
  return parseFloat(currency) * negative
}

export {
  format,
  unformat
}
