<template>
  <router-view></router-view>
</template>

<style>
@import '~flag-icons/css/flag-icons.min.css';
</style>
<style lang="scss">
// Main demo style scss
@import "assets/sass/style.vue";
</style>

<script>
export default {
  name: 'App'
}
</script>
