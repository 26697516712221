import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ApiService from './common/api.service'

import i18n from './common/plugins/vue-i18n'
import vuetify from './common/plugins/vuetify'
// import '@babel/polyfill'
import '@mdi/font/css/materialdesignicons.css'
import VueGtag from 'vue-gtag'

import gtagConfig from '@/config/gtag.json'
import currencyFormat from './filters/currency'

Vue.config.productionTip = false
Vue.filter('currencyFormat', currencyFormat)

ApiService.init()

Vue.use(VueGtag, {
  config: { id: gtagConfig.id }
}, router)

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
