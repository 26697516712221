import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/',
      component: () => import('@/views/templates/Base'),
      children: [
        {
          path: '/',
          name: 'index',
          component: () => import('@/views/pages/Main.vue')
        },
        {
          path: '/plans',
          name: 'plans',
          component: () => import('@/views/pages/Plans.vue')
        }
      ]
    },
    {
      path: '/',
      redirect: '/',
      component: () => import('@/views/templates/BaseEmpty'),
      children: [
        {
          path: '/gracias',
          name: 'formContactComplete',
          component: () => import('@/views/pages/FormContactComplete.vue')
        }
      ]
    }
  ]
})
