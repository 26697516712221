import Vue from 'vue'
import Vuex from 'vuex'

import webPage from './webPage.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    webPage
  }
})
