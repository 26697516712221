export default {
  state: {
    companyTypes: [
      {
        // 'Peluquerías',
        i18nPath: 'companyTypes.names.hairdressers',
        img: 'hairdressing.png',
        id: 'hairdressing',
        text: ''
      },
      {
        // 'Hair spa Salon'
        i18nPath: 'companyTypes.names.hair_spa_salon',
        img: 'hair_spa_salon.png',
        id: 'hair_spa_salon',
        text: ''
      },
      {
        // 'Barberías',
        i18nPath: 'companyTypes.names.barbershops',
        img: 'barbershops.png',
        id: 'barbershops',
        text: ''
      },
      {
        // 'Spa de cejas y pestañas',
        i18nPath: 'companyTypes.names.eyebrow_and_lash_spa',
        img: 'eyebrow_and_eyelash_spa.png',
        id: 'eyebrow_and_eyelash_spa',
        text: ''
      },
      {
        // 'Salón de uñas',
        i18nPath: 'companyTypes.names.nail_salon',
        img: 'nail_salon.png',
        id: 'nail_salon',
        text: ''
      }
      /*  {
        // 'Spa',
        i18nPath: 'companyTypes.names.spa',
        img: 'spa.png',
        id: 'spa',
        text: ''
      }, */
      /*  {
        // 'Centros de estética',
        i18nPath: 'companyTypes.names.beauty_centers',
        img: 'beauty_centers.png',
        id: 'beauty_centers',
        text: ''
      } */
    ]
  },
  getters: {
    companyTypes (state) {
      return state.companyTypes
    }
  }
}
